@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cambay&display=swap");

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
body:hover::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
}

/* Handle */
body:hover::-webkit-scrollbar-thumb {
  background-color: rgba(199, 167, 111, 0.726);
  border-radius: 3px;
}
